import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormComponent, UserService, Auth, OktaService } from '../../shared';

@Component({
    selector: 'mxc-expired-password',
    templateUrl: './expired-password.component.html',
})
export class ExpiredPasswordComponent extends FormComponent implements OnInit {
    stateToken: string;
    changePasswordForm: FormGroup;
    userName = false;
    passwordToCheck = '';
    oktaError = false;

    constructor(
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private userService: UserService,
        private oktaService: OktaService,
    ) {
        super();
        this.changePasswordForm = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', Validators.required, this.passwordValidator.bind(this)],
            confirmPassword: ['', Validators.required, this.generatePasswordConfirmationValidator('changePasswordForm').bind(this)],
        });
    }

    ngOnInit(): void {
        this.activatedRouter.paramMap.subscribe((params) => {
            this.stateToken = params.get('stateToken');
        });
    }

    submit(): void {
        this.userService.resetExpiredPassword(
            this.stateToken,
            this.changePasswordForm.get('oldPassword').value,
            this.changePasswordForm.get('newPassword').value,
        ).subscribe((res: Auth) => {
            let redirectLocationHostProtocol = 'https';
            if (location.host.toString().includes('localhost')) {
                redirectLocationHostProtocol = 'http';
            }
            const link = (`${redirectLocationHostProtocol}://${location.host}/${
                !res.features ? 'apps' :
                res.features.filter(f => f.slug === 'home').length > 0 ? 'home' : 'apps'
            }`);

            if (res.eulaAccepted) {
                this.oktaService.getAuthClient().session.setCookieAndRedirect(res.sessionToken, link);
            } else {
                this.router.navigate(['/login']);
            }
        },
        (res) => {
            this.oktaError = res.error.data;
        });
    }
}
